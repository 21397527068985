import { strict } from 'assert';
import algoliasearch from 'algoliasearch';
import aa from 'search-insights';

strict(process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID);
strict(process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY);

export const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
);

const PRODUCTION_INDEX_MAP = {
  INTEGRATIONS: 'integration_catalog',
  POSTS: 'posts',
  RESOURCES: 'resources',
} as const;

const DEV_INDEX_MAP = {
  INTEGRATIONS: 'DEV_integration_catalog',
  POSTS: 'DEV_posts',
  RESOURCES: 'DEV_resources',
} as const;

// Ensure that the dataset and algolia index are the same
export const INDEX_MAP =
  process.env.NEXT_PUBLIC_SANITY_DATASET === 'production'
    ? PRODUCTION_INDEX_MAP
    : DEV_INDEX_MAP;

/**
 * Algolia Insights API
 * @see https://www.algolia.com/doc/api-client/methods/insights/
 */
export const algoliaInsightsClient = aa;
algoliaInsightsClient('init', {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
  useCookie: true,
});
